<template>
  <div id="episodeListingContainer">
    <div class="mainLayout" v-if="checkSeries && dataFetched">
      <div class="EmptyContainer" v-if="seasons.length == 0">
        <EptyBin></EptyBin>
        <p> {{$t("No contents found")}} </p>
      </div>
      <div v-else class="content-container">
        <div class="mainLayoutSeason">    
          <CustomSelect
            :options="seasons"
            width="150"
            @onSelectChange="onItemSelected"
          />
        </div>      
        <!-- <Grid :contents="episodes" :isScrollable="isScrollable" @loadMore="nextPageDataset()"></Grid> -->
        <div class="gridMainLayout">
          <ul :class="[localDisplayLang === 'ara' ? 'episodeCardsAra' : 'episodeCards']" class="gridContainer">
            <li class="gridCardWrapper" v-for="(episode, index) in episodes" :key="episode.objectid" @click="playContent(episode, index)">
              <div class="gridCardLayout">
                <div class="gridPosterLayout">
                  <img :src="getPoster(episode)" class="episodePoster" alt="" />
                  <div class="play-img">
                    <img src="@/assets/icons/Ic_Play1.svg" alt="" width="36" height="36"/>
                  </div>
                </div>
                <div v-if="isObjectTag(episode) && pickTagForContent(episode)" :class="[localDisplayLang === 'ara' ? 'episodeTagRight' : 'episodeTag']">
                  <img src="@/assets/icons/Premium_1_scg.svg" alt="crown">
                </div>
                <div class="gridContentLayout">
                  <div class="gridContentTitle">
                    <p class="gridTitle" :title="episode.title">{{ episode.title }}</p>
                  </div>
                  <div class="gridContentDetails">
                    <div class="main-info">
                      <div class="ua-rating" v-if="episode.advisory">
                        <p>{{ episode.advisory }}</p>
                      </div>
                      <div class="publish-year" v-if="episode.productionyear">
                        <p>{{ episode.productionyear }}</p>
                      </div>
                      <div class="duration" v-if="episode.duration">
                        <p>{{ calcTimeFormat(episode.duration) }}</p>
                      </div>
                      <div class="pgrating" v-if="episode.pgrating">
                        <p>{{ episode.pgrating }}</p>
                      </div>
                    </div>
                    <div class="main-genre">
                      <div class="sub-genre1" v-if="episode.category">
                        <p>{{ $t(episode.category) }}</p>
                      </div>
                      <div class="sub-genre2" v-if="episode.genre">
                        <p>{{ $t(episode.genre) }}</p>
                      </div>
                      <div class="sub-genre3" v-if="episode.contentlanguage.length > 0">
                        <p>{{ $t(episode.contentlanguage[0]) }}</p>
                      </div>
                    </div>
                  </div>
                  <p class="gridDescription" v-if="episode.shortdescription || episode.longdescription">
                    {{ getDescription(episode, 150) }}
                  </p>
                </div>
              </div>
            </li>
            <li v-if="isloading" class="gridCardWrapper" v-for="(episode, index) in 6" :key="index">
              <div class="gridCardLayout">
                <div class="gridPosterLayout loading"></div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-else>
      <GridLayoutPlaceholder></GridLayoutPlaceholder>
    </div>
  </div>
</template>

<script>
import { vLivePlaylistItem } from "/src/vue-player/vLiveWebPlayer.js";
import { eventBus } from "@/eventBus";
import Utility from "@/mixins/Utility.js";
import Redirection from "@/mixins/redirection.js";
import firebase from "firebase/app";
import "firebase/database";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { store } from "@/store/store";
export default {
  props: {
    contentDetails: {
      type: Object,
    },

    playerInstance: {
      type: Object,
    },
		cbPlayerCall: {
			type: String
		}
  },
  data() {
    return {
      availabilities: [],
      subscriptions: [],
      purchases: [],
      filteredAvailabilities: [],
      playbackInitiated: false,
      pricemodel: [],
      dataFetched: false,
      seasonNum: 1,
      seasonCount: null,
      localDisplayLang: null,
      seasonCountArray: [],
      seasons: [],
      isMpegRequired: false,
      episodes: [],
      isloading: true,
      totalEpisodeCount: 0,
      episodePlayFromRoute: false,
      episodePaginatinInProgress: false,
			selectedCurrentEpisode: '',
      isActiveStatus: true,
      rentNowPriceModel: '',
      purchaseListData: '',
      rentPurchasedActive: false,
      userInfoDetails: [],
      currentEpisode: {},
      placeholder_lands: require('@/assets/placeholder-lands.svg'),
      isScrollable: false,
      storeCBLoadContent: '',
    };
  },

  watch: {
    purchaseList (val) {
      this.purchaseListData = val;
      // this.getPurchaseListCode();
    },
    subscriberid(val) {
      if (val) {
        this.getAvailabilityForAllContents(this.episodes);
      }
    },
    subscriptionList(val) {
      if (val.length > 0) {
        this.subscriptions = val;
        this.getAvailabilityForAllContents(this.episodes);
      }
    },
    availabilityList(val) {
      if (Array.isArray(val) && val.length > 0) {
        this.availabilities = val;
        this.getAvailabilityForAllContents(this.episodes);
      }
    },

    episodes(val) {
      if (val) {
        this.getAvailabilityForAllContents(val);
      }

      // if (val.length > 0) {
      //   this.getAudioAndSubtitleLang(val[0]);
      // }
    },
  },
  computed: {
    ...mapGetters([
      "subscriberid",
      "availabilityList",
      "subscriptionList",
      "purchaseList",
      "appConfig",
      "getEpisodePaginationInProgress",
      "profileid",
      
    ]),
    checkSeries: function() {
      if (this.contentDetails.category == "MOVIE") {
        return false;
      } else {
        return true;
      }
    },
  },
  created() {
    if (this.purchaseList) {
      this.purchaseListData = this.purchaseList;

    }
			eventBus.$on('cbPlayerCallCounter', (val) => {
        let selectedEpisodeIndex = this.episodes.findIndex((val) => {return val.episodenum == this.selectedCurrentEpisode.episodenum});
        this.playContent(this.selectedCurrentEpisode, selectedEpisodeIndex)
			})

      eventBus.$on("event-callback-parentallock", (ParentalLockDataset) => {
        this.playContent(ParentalLockDataset[0], ParentalLockDataset[1], true)
      })

      try {
        this.userInfoDetails = localStorage.getItem("subscriberDetails") ? JSON.parse(localStorage.getItem("subscriberDetails")).data : [];
      } catch(err) {
        console.log(err)
      }


    this.episodes = [];
    this.seasons = [];
    //assigning availability list response.

    // this.isMpegRequired = this.checkMpegRequired();
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    
    this.localDisplayLang = this.localDisplayLang == 'ml' ? 'mal' : this.localDisplayLang;

    if (this.availabilityList !== undefined) {
      this.availabilities = this.availabilityList;
    }
    if (this.subscriptionList !== undefined) {
      this.subscriptions = this.subscriptionList;
    }

    eventBus.$on("send-episode-list", (response) => {
      setTimeout(() => {
        this.dataFetched = true;

        this.isloading = false;
      }, 3000)
   
      //  this.episodes = this.episodes.concat(response.data);
      if (!response.reason) {
        if (this.totalEpisodeCount == 0) {
          this.totalEpisodeCount = response.totalcount;
        }
        if (this.episodes.length < this.totalEpisodeCount && this.getEpisodePaginationInProgress) {
          let numOfNewEpisodes = response.data.length;
          this.episodes = this.episodes.concat(response.data);
          this.isScrollable = response.totalcount == this.episodes.length ? false : true;       
          if (this.subscriberid || this.profileid) {
					  this.actGetLatestEpisode()
          } else {
            this.$emit('totalEpisodeListCB', this.episodes); 
            this.selectedCurrentEpisode = this.episodes && this.episodes.length ? this.episodes[0] : null;
            this.$emit('setCurrentEpisodeInformation', this.selectedCurrentEpisode);  
          }
          if (this.playbackInitiated == true) {
            let deltaPlaylist = this.buildPlayerPlaylist(this.episodes, this.episodes.length - numOfNewEpisodes);
            this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
          }
        }
      } else {
        this.episodes = [];
        this.totalEpisodeCount = 0;
        this.$emit('totalEpisodeListCB', false)
      }

      this.setEpisodePaginationInProgress(false);

      this.getAvailabilityForAllContents(this.episodes);
    });

    let deeplinkEpisodeId = this.$route.params.episodeId;
    if (deeplinkEpisodeId && this.$route.params.isPlayBackActive === "play") {
      // eventBus.$emit("startLoader");
      this.episodePlayFromRoute = true;
      this.getContent(deeplinkEpisodeId).then((res) => {
        let payload = {
          subscriptionstatus: "ALL",
        };
        store.dispatch("listSubscription", payload).then((response) => {
          if (!response.data.reason) {
            store.commit("setSubscriptionList", response.data.data);
            let pageSize = this.appConfig.defaultpageSize;
            let currentPageCount = pageSize;
            let morEpisodeCall = [];
            let counter = 1;
            while (this.totalEpisodeCount > currentPageCount) {
              let pageNum = ++counter;
              currentPageCount = currentPageCount + pageSize;
              let seriesId = this.$route.params.contentId;
              // let seasonNum = 1;
              let payload = {
                objecttype: "CONTENT",
                seriesid: seriesId,
                seasonnum: this.seasonNum,
                pagesize: pageSize,
                page: pageNum,
                displaylanguage: this.localDisplayLang,
              };

              morEpisodeCall.push(this.listContent(payload));
            }

            Promise.all(morEpisodeCall).then((response) => {
              if (response[0] && response[0].data && response[0].data.data) {
                this.episodes = [...this.episodes, ...response[0].data.data];
              }
              let currentIndex = 0;

              for (let index in this.episodes) {
                if (this.episodes[index].objectid == deeplinkEpisodeId) {
                  currentIndex = parseInt(index);
                  break;
                }
              }
              this.loadChosenIndexContent(currentIndex, this.episodes, this.episodes.length);
            });

            // this.$router.push({name:"trailerPlaybackSeries" , params:{ isPlayBackActive: "play", trailerId: this.$route.params.episodeId}})
          }
        });
      });
    };

    eventBus.$on("season_listing_response", (response) => {
      
      if (response.reason) {
        this.episodeList = [];
        this.seasons = [];
      } else {
        this.seasonCount = response?.totalCount;
        this.seasons = response?.data;
        
        this.seasonNum = this.seasons?.[0]?.['seasonnum'];
        this.getSeasonContents();
        // this.getLatestSeason();
      }
    });
  },
  mounted() {
    // this.showPlayer();
    this.getSeasonCount();
    this.playerInstance.registerEvent("currentcontentended", this.loadNextContentData); // currentcontentended
    this.playerInstance.registerEvent("loadchosencontent", this.loadChosenContentData); // Playlist Item selected time
    // this.playerInstance.registerEvent("loadchosencontent", this.storeCBLoadContent); // Adjust delay (300ms) as needed
    this.playerInstance.registerEvent("loadmorecontent", this.loadMoreContentData);


    // this.storeCBLoadContent = this.debounce(this.loadChosenContentData, 300);
    this.episodes = [];
    this.seasons = [];
    this.setAudioAndSubtitleLangState(null);
    // this.getSeasonContents();
    eventBus.$on("backfrom-player", () => {
      if (this.subscriberid || this.profileid) {
					  this.actGetLatestEpisode()
          }
    })

    if(screen.height >= 1440) {
      this.debounce(this.nextPageDataset, 2000);
    }

    window.addEventListener("scroll", () => {

      let scrollHeight, totalHeight;
      scrollHeight = document.body.scrollHeight;
      totalHeight = window.scrollY + window.innerHeight;

      if (totalHeight >= scrollHeight) {
        this.debounce(this.nextPageDataset, 100);
      }
    });

    // this.seasonNum = 1;
    eventBus.$on("callAvailability", () => {
      this.getAvailabilityForAllContents(this.episodes);
    });
  },
  methods: {
    ...mapMutations([
      "setRegActiveDeeplink",
      "setSubscriptionList",
      "setAudioAndSubtitleLangState",
      "setEpisodePaginationInProgress",
    ]),
    ...mapActions(["getContent", "listContent", "authorizeCurrentStreamApi", "setCurrentStreamApi",  "availabilityDetail"
]),
    getPurchaseListCode (contentId) {
      for(let list of this.purchaseListData) { 

       if ( (list.objectid).toLowerCase() == contentId) {
          return true;          
       }  
      }
      return false;
    },
    getPoster(content) {
      if (content && content.poster && content.poster.length && !content.inwatchlist) {
        let index = content.poster.findIndex((element) => {
          return element.postertype === "LANDSCAPE";
        });

        if (index > -1) {
          
          if(content.poster[index] && content.poster[index].filelist && content.poster[index].filelist.length > 1){
            return content.poster[index].filelist[1].filename;
          } else if(content.poster[index] && content.poster[index].filelist && content.poster[index].filelist.length == 1)
            return content.poster[index].filelist[0].filename;
        } else {
          return this.placeholder_lands
        }
      } else if (content.inwatchlist) {
        return content.poster.landscape;
      } else {
        return this.placeholder_lands
      }
    },
    pickTagForContent(content) {
      if (content && content.hasOwnProperty("objecttag")) {
        let tag = content.objecttag[0];
        let contenttags = this.appConfig.contentTags;
        if (this.localDisplayLang === "ara") {
          return contenttags.ar[`${tag}`];
        } else {
          return contenttags.en[`${tag}`];
        }
      }
    },
		actGetLatestEpisode () {
	    this.checkEpisodeContinueWatch().then((progressData) => {
        progressData = progressData.val();
			let currentIndex = 0;
			for (let index in progressData) {
				if (index == this.contentDetails.objectid) {
					currentIndex = index;
					break;
				}
			}
      if (progressData && progressData[currentIndex] && progressData[currentIndex].episodes) {
        let allSortEpi	= Object.values(progressData[currentIndex].episodes).sort((a, b) => {
                  return b.updatedat - a.updatedat;
            });
        this.selectedCurrentEpisode = allSortEpi[0]
      } else {
        this.selectedCurrentEpisode = this.episodes[0]
      }
       if (this.selectedCurrentEpisode) {
          for (let episode in this.episodes) {
            if (this.episodes[episode].objectid == this.selectedCurrentEpisode.objectid) {
              this.episodes[episode].watchedduration = this.selectedCurrentEpisode.watchedduration;
              this.episodes[episode].duration = this.selectedCurrentEpisode.duration;
              this.selectedCurrentEpisode = this.episodes[episode];
              break;
            }
          }
          this.$emit('setCurrentEpisodeInformation', this.selectedCurrentEpisode);
          eventBus.$emit('currentEpisodeData', this.selectedCurrentEpisode);
        }
      });		
		},
    checkEpisodeContinueWatch() {    
      this.continueWatchContent = [];
      this.continueWatchData = [];
      this.detailPlaylist = [];
      this.finalPlaylist = [];
      this.completeEpisodeListing = [];
      this.continueData = [];
      let path = "";  
      if (this.subscriberid || this.profileid) {
        path = `/subscriber/${this.subscriberid}/${this.profileid}/content/`;
       return firebase.database().ref(path).once("value")
      } else {
        this.selectedCurrentEpisode = this.episodes[0];
        this.$emit('setCurrentEpisodeInformation', this.selectedCurrentEpisode);
        eventBus.$emit('currentEpisodeData', this.selectedCurrentEpisode);
      }
    },
    checkLanguage() {
      const seriesObjIds = [];
      this.continueWatchDataTemp.forEach((element) => {
        if (element.category == "TVSHOW") {
          seriesObjIds.push(`"${element.objectid}"`);
        } 
			else if (element.category == "MOVIE") {	
          moviesObjIds.push(`"${element.objectid}"`);
        }
      });
      let seriesPromise;
      let moviesPromise;
      if (seriesObjIds && seriesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + seriesObjIds + "]",
          displaylanguage: this.localDisplayLang,
        };
        seriesPromise = new Promise((resolve, reject) => {
          this.listContent(payload).then((data) => {
            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.data.data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...content,
                    title: data.title,
                    seriesname: data.seriesname,
                  };
                }
              });
            });
            resolve(this.continueWatchDataTemp);
          });
        });
      }
      if (moviesObjIds && moviesObjIds.length > 0) {
        const payload = {
          contentlist: "[" + moviesObjIds + "]",
          displaylanguage: this.localDisplayLang,
          objecttype: "MOVIE",
        };
        moviesPromise = new Promise((resolve, reject) => {
          this.listContent(payload).then((data) => {
            

            this.continueWatchDataTemp.forEach((content, cIndex) => {
              data.data.data.forEach((data, dIndex) => {
                if (data.objectid === content.objectid) {
                  this.continueWatchDataTemp[cIndex] = {
                    ...content,
                    title: data.title,
                  };
                }
              });
            });
            resolve(this.continueWatchDataTemp);
          });
        });
      }

      Promise.all([seriesPromise, moviesPromise]).then((finalData) => {
        this.continueWatchData = finalData[1];
        if (this.continueWatchData && this.continueWatchData.length > 0) {
          this.continueWatchData = this.continueWatchData.filter((el) => {
            return el.status === "INPROGRESS";
         });
          this.continueData = this.continueWatchData.sort((a, b) => {
            return b.updatedat - a.updatedat;
          });
        }

        this.showContinuePlaceHolder = false;
        this.constructSlider();
      });
    },
    isObjectTag(content) {
      if (content.hasOwnProperty("objecttag")) {
        if (Array.isArray(content.objecttag)) {
          return content.objecttag.length === 0 ? false : true;
        } else {
          return content.objecttag === null ? false : true;
        }
      }
    },

    getAudioAndSubtitleLang(content) {
      const packageData = this.getFilteredPackage(content);

      const langPayload = { audiolang: packageData.audiolang, subtitlelang: packageData.subtitlelang };

      this.setAudioAndSubtitleLangState(langPayload);
    },
    nextPageDataset() {
      if (this.episodes.length < this.totalEpisodeCount && this.isloading == false) {
        this.isloading = true;        
        // this.isScrollable = true,
        this.fetchEpisodeList(this.contentDetails.objectid, this.seasonNum, 'nextPageDataset');
      }
    },
    debounce(method, delay) {
      clearTimeout(method._tId);
      method._tId = setTimeout(function() {
        method();
      }, delay);
    },
    checkMpegRequired() {
      let isMpegRequired = false;
      let videoTag = document.createElement("video");
      let platformOs = navigator.platform;

      if (videoTag.canPlayType("application/vnd.apple.mpegurl")) {
        if (platformOs.startsWith("Mac")) {
          isMpegRequired = true;
        }
      }

      return isMpegRequired;
    },
    getAvailabilityForAllContents(val) {
      val.forEach(async (element) => {
        if (this.subscriberid) {
          await this.getAvailability(element);
        }
        element.type = "inner";
      });
    },
    getSeasonContents() {
      this.isloading = true;
      this.episodes = [];
      this.totalEpisodeCount = 0;
      this.fetchEpisodeList(this.contentDetails.objectid, this.seasonNum, 'getSeasonContents');
    },
    onItemSelected(obj) {
      this.seasonNum = obj.selectedItem['seasonnum'];
  //    alert(seasonNum);
      this.isloading = true;
      this.episodes = [];
      this.totalEpisodeCount = 0;
      // let fullPath = this.$route.fullPath.split("/");
      // window.history.pushState("object or string", fullPath[fullPath.length - 1], `${seasonNum}`);
      //this.$router.push({name:"episodes" , params:{id:this.seasonNum}})
      this.fetchEpisodeList(this.contentDetails.objectid, this.seasonNum, 'onItemSelected');
    },
    getSeasonCount() {
      //console.log("get season count");
      let payload = {
        objecttype: "SEASON",
        seriesid: this.contentDetails.objectid,
      };
      eventBus.$emit("get-seasonnum-listing", payload);
    },

    fetchEpisodeList(seriesId, seasonNum, type) {
      let pageSize = this.appConfig.defaultpageSize;
      let pageNum = Math.floor(this.episodes.length / pageSize) + 1;

      let payload = {
        objecttype: "CONTENT",
        seriesid: seriesId,
        seasonnum: seasonNum,
        pagesize: pageSize,
        page: pageNum,
        displaylanguage: this.localDisplayLang,
      };

      if (this.isMpegRequired == true) {
        payload.mpegtspackage = "YES";
      }

      this.episodePaginatinInProgress = true;
      eventBus.$emit("get-episode-list", payload);
    },

    buildContentStreamRequest(contentItem, isMpegRequired) {
      let payload;
      if (contentItem.hasOwnProperty("playback_details")) {
        payload = {
          contentid: contentItem.objectid,
          params: {
            availabilityid: contentItem.playback_details.availabilityid,
            packageid: contentItem.playback_details.packageid,
          },
        };

        if (isMpegRequired == true) {
          payload.params.mpegtspackage = "YES";
        }
      } else {
        this.hidePlayer();

        this.setRegActiveDeeplink(true);

        // Display Subscriptions popup.
        // let payload = { listType: "onlySubscribe" };
        // eventBus.$emit("subscribePopup", payload);
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});
      }

      return payload;
    },
    fetchContentStreamUrl(contentItem, isMpegRequired) {
      return new Promise((resolve, reject) => {
        if (contentItem.streamUrl != undefined && contentItem.streamUrl != "") {
          resolve(true);
        } else {
          resolve(store.dispatch("contentStreamUrl", this.buildContentStreamRequest(contentItem, isMpegRequired)));
        }
      });
    },
    fetchContentDetails(contentItem, isMpegRequired) {
      // console.log("Content item : ", contentItem);
      if(!contentItem) return;
      return new Promise((resolve, reject) => {
        if (contentItem?.contentdetails) {
          resolve(true);
        } else {
          let requestData = {};
          let queryParams = {};

          queryParams.displaylanguage = this.localDisplayLang;

          if (isMpegRequired == true) {
            queryParams.mpegtspackage = "YES";
          }

          requestData.contentid = contentItem?.objectid;

          requestData.params = queryParams;

          resolve(store.dispatch("contentDetail", requestData));
        }
      });
    },
    handleFetchContentStreamUrl(response, contentItem) {
      contentItem.streamUrl = response.data.streamfilename;
      contentItem.scrubbing = response.data.packagedfilelist && response.data.packagedfilelist.scrubbing ? response.data.packagedfilelist.scrubbing : '';

    },

    async getAvailability(contentItem) {
      if (contentItem.hasOwnProperty("contentdetails") && contentItem.contentdetails.length > 0) {
        contentItem.isContentDetail = true;
        // let packageDetails = this.getFilteredPackage(contentItem);

        // let availPlanList = this.getAvailabilityAndPlanDetails(packageDetails, this.availabilities);
        // let filteredAvailabilityList = availPlanList.filteredAvailabilities;
        let { filteredAvailabilityList, packageDetails } = await this.determineContentPackageAvailabilityDetails(contentItem, this.availabilities);
        const langPayload = {
          audiolang: packageDetails && packageDetails?.[0]?.audiolang,
          subtitlelang: packageDetails && packageDetails?.[0]?.subtitlelang,
        };
        this.setAudioAndSubtitleLangState(langPayload);
        filteredAvailabilityList.every((availability) => {
          if (availability.pricemodel === "PLAN") {
            if (this.subscriberid && this.subscriptions.length > 0) {
              this.subscriptions.every((subscription) => {
                // Check if next billing date is greater than today's date.
                if (this.getDateForPlayback(subscription.nextbilling) < this.getTodaysDate()) return;

                let value = subscription.availabilityset.includes(availability.availabilityid);

                if (value) {
                  contentItem.isDrmContent = true;
                  contentItem.videoType = "Content";
                  //creating this playback_details for content playback.
                  contentItem.playback_details = {
                    packageid: packageDetails.packageid,
                    availabilityid: availability.availabilityid,
                    drmscheme: packageDetails.drmscheme && packageDetails.drmscheme[0],
                  };

                  return false;
                }
              });
            } else if (this.subscriberid && this.subscriptions.length === 0) {
              // console.log(
              //   "content price model is not free and user has to subscribe to the plan to watch this content"
              // );
            }
          } else if (this.subscriberid && (availability.pricemodel == "RENTAL" || availability.pricemodel == "PAID")) {
           let isEnablePlay = false;
            for (let list of this.purchaseListData) { 
                  if ( (list.objectid) == contentItem.objectid) {
                    isEnablePlay =  true;          
                  }  
              }

            if (isEnablePlay)  {

              contentItem.isDrmContent = true;
              this.isButtonEnable = true;
              // let selectedAvailability = '';
              contentItem.videoType = "Content";
              console.error("filteredAvailabilityList", filteredAvailabilityList)

              contentItem.playback_details = {
                packageid: packageDetails.packageid,
                availabilityid: availability.availabilityid,
                drmscheme: packageDetails.drmscheme && packageDetails.drmscheme[0],
              };

              return;
            }

            this.availabilityDetail(availability.availabilityid).then((res) => {
              let userCountryCurrency = this.userInfoDetails.subscribercountry == 'IN' ? 'INR' : 'USD';
              let currentPriceItem = {};
                for (let Item of res.data.priceclassdetail)  {
                    if (Item.currency == userCountryCurrency) {
                      currentPriceItem = Item;
                    }
                }
                console.error( contentItem, "============")

              this.rentNowPriceModel = {};
              this.rentNowPriceModel.planname = contentItem.title;
              this.rentNowPriceModel.amount =  currentPriceItem.price;
              this.rentNowPriceModel.planid = currentPriceItem.priceclassid;
              this.rentNowPriceModel.priceclassid = currentPriceItem.priceclassid;
              this.rentNowPriceModel.availabilityid = availability.availabilityid;
              this.rentNowPriceModel.planinterval = res.data.licenseduration;
              this.rentNowPriceModel.currency = currentPriceItem.currency;
              this.rentNowPriceModel.rental = "PURCHASE";
              this.rentNowPriceModel.getPoster = this.getPoster(contentItem);
              this.rentNowPriceModel.objectid  = contentItem.objectid

        
              contentItem.rentNowPriceModel = this.rentNowPriceModel
              
            })
          } else if (availability.pricemodel == "FREE") {
            contentItem.isDrmContent = true;
            contentItem.videoType = "Content";
            //creating this playback_details for content playback.
            contentItem.playback_details = {
              packageid: packageDetails.packageid,
              availabilityid: availability.availabilityid,
              drmscheme: packageDetails.drmscheme && packageDetails.drmscheme[0],
            };
            return false;
          }
        });
      }  else if (!contentItem.hasOwnProperty("contentdetails")) {
        contentItem.isContentDetail = false;
      }
    },

    getAvailabilityAndPlanDetails(packageDetails, availabilitiesList) {
      let priceAndAvailabilityData = {};
      priceAndAvailabilityData.pricemodel = [];
      priceAndAvailabilityData.filteredAvailabilities = [];

      packageDetails.availabilityset.forEach((availability) => {
        let availabilityIndex = availabilitiesList.findIndex((element) => {
          return element.availabilityid === availability;
        });

        if (availabilityIndex > -1) {
          priceAndAvailabilityData.pricemodel.push(availabilitiesList[availabilityIndex].pricemodel);
          priceAndAvailabilityData.filteredAvailabilities.push(availabilitiesList[availabilityIndex]);
        }
      });

      return priceAndAvailabilityData;
    },

    getFilteredPackage(contentItem) {
      //console.log('content item in get filtered package--------',contentItem)
      let temporaryVideoTag = document.createElement("video");
      let hasNativeHlsSupport = temporaryVideoTag.canPlayType("application/vnd.apple.mpegurl");

      let isAndroid = navigator.userAgent.includes("Android");

      let packageList = [];
      this.availabilities.forEach((availability) => {
        contentItem.contentdetails.forEach((element) => {
          if (element.availabilityset.includes(availability.availabilityid)) {
            packageList.push(element);
          }
        });
      });
      let packageIndex = packageList.findIndex((contentPackage) => {
        if (!isAndroid && hasNativeHlsSupport != "") {
          return (contentPackage.drmscheme && contentPackage.drmscheme[0] === "FAIRPLAY") || (contentPackage.drmprovider && contentPackage.drmprovider.toLowerCase() === "buydrm");
        } else {
          return (contentPackage.drmscheme && contentPackage.drmscheme[0] === "WIDEVINE") || (contentPackage.drmprovider && contentPackage.drmprovider.toLowerCase() === "buydrm");
        }
      });
      if (packageIndex == -1) {
        return packageList[
          packageList.findIndex((contentPackage) => {
            return packageList.drmscheme[0] === "NONE";
          })
        ];
      } else if (packageIndex > -1) {
        return packageList[packageIndex];
      }
    },

    loadChosenIndexContent(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      this.fetchContentDetails(episodeList[episodeIndex], this.isMpegRequired)
        .then((resp) => {
          if (typeof resp == "object" && !resp.data.errorcode) {
            episodeList[episodeIndex].title = resp.data.title;
            episodeList[episodeIndex].contentdetails = resp.data.contentdetails;
            episodeList[episodeIndex].seriesname = resp.data.seriesname;
          } else if (typeof resp == "boolean" && resp == true) {
            // console.log("Content detail present");
          } else {
            let playbackError = true;

            if (typeof resp == "object") {
              playbackError = resp.data;
            }
            throw playbackError;
          }

          this.fetchContentStreamUrl(episodeList[episodeIndex], this.isMpegRequired).then((resp) => {
            let loadPlayer = false;
            this.showPlayer();

            if (this.appConfig.featureEnabled.isConcurrencyEnabled) {
              // Call the Stream Authorize API
              this.authorizeCurrentStreamApi().then((authResponse) => {
                if (!authResponse.data.errorcode) {
                  if (typeof resp == "object" && !resp.data.errorcode) {
                    this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                    loadPlayer = true;
                  } else if (typeof resp == "boolean" && resp == true) {
                    loadPlayer = true;
                  }
                  if (loadPlayer == true) {
                    this.loadContentMetaData(episodeList[episodeIndex], null);

                    if (typeof resp == "object") {
                      if (isFirstTime == true) {
                        let playlist = this.buildPlayerPlaylist(episodeList, 0);
                        this.playerInstance.loadPlaylist(playlist, totalCount);
                      } else {
                        let playlist = this.playerInstance.playlist;
                        let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                        playlist.updateItem(episodeIndex, updatedContentItem);
                      }
                    }

                    this.playerInstance.loadContent(episodeIndex).then(() => {
                      this.playerInstance.play().then(() => {
                        //eventBus.$emit("endLoader")

                        const payload = {
                          contentId: episodeList[episodeIndex].objectid,
                          params: {
                            devicetype: "PC",
                          },
                        };

                        this.setCurrentStreamApi(payload).then((setStreamResponse) => {
                        });
                      });
                    });
                  } else {
                    let playbackError = true;

                    this.hidePlayer();

                    eventBus.$emit("showAuthorizeErrorMessage", {
                      state: true,
                      messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                    });

                    if (typeof resp == "object") {
                      playbackError = resp.data;
                    }
                    throw playbackError;
                  }
                } else {
                  let playbackError = true;

                  this.hidePlayer();

                  eventBus.$emit("showAuthorizeErrorMessage", {
                    state: true,
                    messageData: { title: this.$t("Error"), message: this.$t("Device Limit Reached") },
                  });

                  if (typeof resp == "object") {
                    playbackError = resp.data;
                  }
                  throw playbackError;
                }
              });
            }
            // If concurrency is disabled form the Config
            else {
              if (typeof resp == "object" && !resp.data.errorcode) {
                this.handleFetchContentStreamUrl(resp, episodeList[episodeIndex]);
                loadPlayer = true;
              } else if (typeof resp == "boolean" && resp == true) {
                loadPlayer = true;
              }
              if (loadPlayer == true) {
                this.loadContentMetaData(episodeList[episodeIndex], null);

                if (typeof resp == "object") {
                  if (isFirstTime == true) {
                    let playlist = this.buildPlayerPlaylist(episodeList, 0);
                    this.playerInstance.loadPlaylist(playlist, totalCount);
                  } else {
                    let playlist = this.playerInstance.playlist;
                    let updatedContentItem = this.createPlayerPlaylistItem(episodeList[episodeIndex], 0);
                    playlist.updateItem(episodeIndex, updatedContentItem);
                  }
                }

                this.playerInstance.loadContent(episodeIndex).then(() => {
                  this.playerInstance.play().then(() => {
                    console.log("Playback successful");
                  });
                });
              } else {
                let playbackError = true;

                this.hidePlayer();

                if (typeof resp == "object") {
                  playbackError = resp.data;
                }
                throw playbackError;
              }
            }
          });
        })
        .catch((error) => {
          
          this.hidePlayer();
        });
    },

    loadChosenContentData(chosenContentIndex) {
      if(!chosenContentIndex) return;
      console.error("======loadChosenContentData", this.episodes, chosenContentIndex)
      this.playerInstance.unloadPlayer();
      let episodeIndex = chosenContentIndex;
      if ( Array.isArray(this.episodes) && this.episodes?.[episodeIndex]?.playbacktype == "EXTERNALURLBASEDPLAYBACK") {
        this.actBuyDRMToPlayer(episodeIndex, this.episodes, this.totalEpisodeCount, true);
      } else {
        this.loadChosenIndexContent(episodeIndex, this.episodes, this.totalEpisodeCount, false);
      }

    },

    loadMoreContentData() {
      let episodeIndex = this.episodes.length;
      this.fetchEpisodeList(this.contentDetails.objectid, this.seasonNum, 'loadMoreContentData')
      // this.fetchEpisodeList(this.contentDetails.objectid, this.seasonNum, 'loadMoreContentData').then((response) => {
      //   if (!response.data.errorcode) {
      //     let episodeList = response.data.data;
      //     this.episodes = this.episodes.concat(episodeList);
      //     let deltaPlaylist = this.buildPlayerPlaylist(this.episodes, episodeIndex);

      //     this.playerInstance.appendPlaylist(deltaPlaylist, this.totalEpisodeCount);
      //   }
      // });
    },

    loadNextContentData(endedContentIndex) {
      let episodeIndex = endedContentIndex + 1;
      let nextepisodeid = this.episodes[endedContentIndex + 1] ? this.episodes[endedContentIndex + 1].objectid : "";
      localStorage.setItem("nextepisodeid", nextepisodeid);
      if (episodeIndex < this.episodes.length) {
        if ( Array.isArray(this.episodes) && this.episodes[episodeIndex].playbacktype == "EXTERNALURLBASEDPLAYBACK") {
          this.actBuyDRMToPlayer(episodeIndex, this.episodes, this.totalEpisodeCount, true);
        } else {
          this.loadChosenIndexContent(episodeIndex, this.episodes, this.totalEpisodeCount, false);
        }
      } else {
        this.hidePlayer();
      }
    },

    showPlayer() {
      try {
      
        this.playbackInitiated = true;
        eventBus.$emit("player-visibility", true);
      } catch (error) {
        console.log("ERROR====", error);
      }
    },

    playContent (content, contentIndex, isActiveStatus=false) {
      this.currentEpisode = content;
      if(content.playbacktype == 'LOCATORBASEDPLAYBACK') {
        this.redirectToLocator(this.currentEpisode);
        return;
      }
      if (!this.isContentPlayableParentalLock(this.getCurrentProfile(), content.pgrating) && !isActiveStatus) {
        eventBus.$emit('event-parental-lock-CB',[content, contentIndex]);
        return;
      }
      let nextepisodeid = this.episodes[contentIndex + 1] ? this.episodes[contentIndex + 1].objectid : "";
      localStorage.setItem("nextepisodeid", nextepisodeid);
      this.episodePlayFromRoute = false;
      let fullPath = location.pathname.split("/");
      if (
        this.subscriberid &&
        this.episodes[contentIndex]?.isContentDetail &&
        this.episodes[contentIndex].hasOwnProperty("playback_details")
      ) {
        if ( Array.isArray(this.episodes) && this.episodes[contentIndex].playbacktype == "EXTERNALURLBASEDPLAYBACK") {
          this.actBuyDRMToPlayer(contentIndex, this.episodes, this.totalEpisodeCount, true);
        } else {
          this.loadChosenIndexContent(contentIndex, this.episodes, this.totalEpisodeCount, true);
        }
        this.showPlayer();
        // this.loadChosenIndexContent(contentIndex, this.episodes, this.totalEpisodeCount, true);
        // if (content.category == "MOVIE") {
        //   this.episodes = [content];
        //   this.loadChosenIndexContent(contentIndex, this.episodes, this.totalEpisodeCount, true);
        // } else {
        //   if (!fullPath.includes("episode")) {
        //     //alert(content.episodenum)
        //     window.history.pushState(
        //       "object or string",
        //       {},
        //       this.$route.params.id + "/episode/"+content.episodenum+"/"+ content.objectid + "/play"
        //     );
        //   } else {
        //     const url = new URL(window.location);
        //     let pathname = url.pathname.split("/");
        //     let finalUrl = `${window.location.origin}/series/${pathname[2]}/${pathname[3]}/${pathname[4]}/${pathname[5]}/episode/${content.episodenum}/${content.objectid}/play`;
        //     finalUrl = finalUrl.replace('series/series/', 'series/')
        //     window.history.pushState("object or string", {}, finalUrl);
        //   }

        //   this.loadChosenIndexContent(contentIndex, this.episodes, this.totalEpisodeCount, true);
        // }
      } else if (
        this.subscriberid &&
        content?.isContentDetail &&
        (this.subscriptions.length === 0 || this.subscriptions.length > 0) &&
        this.subscriberid !== this.profileid
      ) {

        eventBus.$emit("showAuthorizeErrorMessage", {
          state: true,
          messageData: {
            title: this.$t("Error"),
            message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin"),
          },
        });
      }

      // User is logged in and has no subscription -- Master Profile
      else if (
        this.subscriberid &&
        content?.isContentDetail &&
        (this.subscriptions.length === 0 || this.subscriptions.length > 0)
      ) {
        this.setRegActiveDeeplink(true);

        //show subscribe popup.
        let payload = { listType: "onlySubscribe" };
        localStorage.setItem("payment_source", "Content Details");
          if (this.subscriberid !== this.profileid) {
              eventBus.$emit("showAuthorizeErrorMessage", {
              state: true,
              messageData: {
                title: this.$t("Error"),
                message: this.$t("Sorry! Admin only allowed to the payment. Please contact your Admin")
              },
            });
            return;
          }
          if (content.rentNowPriceModel) {
            eventBus.$emit("open-home-rzp-modal", content.rentNowPriceModel);
            return;
          }
    
        let currentLanguage = this.getCurrentLanguageUrlBase();
        this.$router.push({name: 'seeAllPlans', params: {lang: currentLanguage}});
      } else if (this.subscriberid && !content?.isContentDetail) {
        //show snackbar component.
        eventBus.$emit("replace-content", content);
      } else {
        //open lookup popup.
        let payload = {
          state: true,
          formType: "lookup",
        };
        eventBus.$emit("authPopup", payload);
      }
    },
    actBuyDRMToPlayer(episodeIndex, episodeList, totalCount, isFirstTime = true) {
      this.playerInstance.unloadPlayer();

      this.loadContentMetaData(episodeList[episodeIndex], null);
      let playlist = this.buildPlayerPlaylist(episodeList, false, episodeIndex);
      this.playerInstance?.loadPlaylist(playlist, totalCount);
      this.playerInstance?.loadContent(episodeIndex).then(() => {
        this.playerInstance?.play().then(() => {
          console.log("Playback successful");
        });
      }).catch((err) =>{alert(err)});
    },

    buildPlayerPlaylist(episodeList, isTrailer, startIndex) {
      let playlist = [];
      for (var aIndex = 0; aIndex < episodeList.length; aIndex++) {
        let playlistItem = this.createPlayerPlaylistItem(episodeList[aIndex], "watchedduration" in episodeList[aIndex] ? episodeList[aIndex].watchedduration : 0);
        playlist.push(playlistItem);
      }

      return playlist;
    },

    loadContentMetaData(contentMetaData, seriesMetaData) {
      let metaData = {
        content: contentMetaData,
        seriesContent: seriesMetaData,
      };

      eventBus.$emit("load-player-content", metaData);
    },

    hidePlayer() {
      this.playerInstance.shrinkPlayer();
      eventBus.$emit("player-visibility", false);

      let payload = { content: null, seriesContent: null };
      eventBus.$emit("load-player-content", payload);

      this.playerInstance.unloadPlayer(true);
    },

    extractThumbnailPoster(contentItem) {
      let posterKey = contentItem.poster;
      let thumbNailUrl = null;
      if (posterKey && Array.isArray(posterKey) == true && posterKey.length > 0) {
        // let posterFileList = posterKey[0].filelist;
        let posterFileList = posterKey?.find((posterKeyNode) => posterKeyNode.postertype == "LANDSCAPE")?.filelist;
        if (posterFileList && Array.isArray(posterFileList) == true && posterFileList.length > 0) {
          let thumbNailNode = posterFileList.find((posterNode) => posterNode.quality == "THUMBNAIL" || posterNode.quality == "SD" || posterNode.quality == "HD");
          if (thumbNailNode) {
            thumbNailUrl = thumbNailNode.filename;
          }
        }
      }
      return thumbNailUrl;
    },

    createPlayerPlaylistItem(content, playbackPosition) {
      let playlistItem = new vLivePlaylistItem({
        contentId: content.objectid,
        contenttype: content.category,
        title: content.title,
        description: content.shortdescription,
        thumbnail: this.extractThumbnailPoster(content),
        source: content.streamUrl != undefined && content.streamUrl != "" ? [content.streamUrl] : [],
        licenseServer: this.appConfig.providerDetails.drmLicenseUrl,
        packageid: content.playback_details ? content.playback_details.packageid : "",
        providerid: this.appConfig.providerDetails.providerId,
        drmscheme: content.playback_details ? content.playback_details.drmscheme : "",
        availabilityid: content.playback_details ? content.playback_details.availabilityid : "",
        providerSession: localStorage.getItem("sessionToken"),
        skip: content.skip && Array.isArray(content.skip) && content.skip.length > 0 ? content.skip : "",
        isDrmContent: content.isDrmContent,
        position: playbackPosition,
        contentTags: content.tags,
        scrubbing: content.scrubbing ?content.scrubbing[0] : '',
        adversity: {
          advisory: content.advisory,
          pgrating: content.pgrating,
          defaultgenre: content.defaultgenre || content.genre
        },
        playbacktype: content.playbacktype || "",
        contentprovider: content.contentprovider || ""
      });
      return playlistItem;
    },
  },
  components: {
    Loading: () => import(/* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"),
    CustomSelect: () => import("@/components/utils/CustomSelect.vue"),
    Grid: () => import(/* webpackChunkName: "Grid" */ "@/components/Templates/GridLayout.vue"),
    EptyBin: () => import(/* webpackChunkName: "eptyBin" */ "@/components/SvgImages/Empty.vue"),
    GridLayoutPlaceholder: () => import(/* webpackChunkName: "gridplaceholder" */ "@/components/placeholders/GridLayoutPlaceholder.vue"),
  },
  beforeDestroy() {
    eventBus.$off("send-episode-list");
    eventBus.$off("callAvailability");
    eventBus.$off("season_listing_response");
  },
  mixins: [Utility, Redirection],
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "@/components/Templates/Grid.scss";
#episodeListingContainer{
  margin-top: 0.8rem;
  .mainLayout {
    .content-container {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
    }
  }

}
.mainLayoutSeason {
  // margin-right: 40px;
  //padding: 0 2rem;
  .select-ara {
    border: 1px solid $clr-light-gd1;
    width: 6%;
    padding: 0.4rem;
    margin: 0;
    margin-left: 0px;
    background: url("../../assets/icons/down_arrow.svg") no-repeat;
    -webkit-appearance: none;
    background-size: 12px;
    background-position-y: 54%;
    cursor: pointer;
    option {
      color: white;
    }
    #season-option {
      background: #161616;
    }
    &:focus {
      background-color: transparent;
    }
    background-position-x: 3%;
  }
  .select-eng {
    border: 1px solid $clr-light-gd1;
    width: 6rem;
    padding: 0.4rem;
    margin: 0;
    margin-left: 0px;
    background: url("../../assets/icons/down_arrow.svg") no-repeat;
    -webkit-appearance: none;
    background-size: 12px;
    background-position-y: 54%;
    cursor: pointer;
    option {
      color: white;
    }
    #season-option {
      background: #161616;
    }
    &:focus {
      background-color: transparent;
    }

    background-position-x: 92%;
  }
}
.play-img {
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    height: 36px !important;
    width: 36px !important;
  }
}
.loading {
  background: linear-gradient(to bottom left, #303030, #242424, #1d1d1d);
  border-radius: 8px;
  overflow: hidden;
}

@media screen and (max-width: 576px) {
  #episodeListingContainer {
    .mainLayoutSeason {
      padding: 0 !important;
      margin-bottom: 0.2rem;
    }
    .gridMainLayout {
      padding: 0 !important;
      .episodeCards {
        grid-template-columns: repeat(auto-fill, minmax(100%, 1fr)) !important;
        .gridCardWrapper {
          aspect-ratio: 16;
          .gridCardLayout {
            flex-direction: row;
            .gridPosterLayout {
              width: 30%;
            }
            .gridContentLayout {
              width: 70%;
              display: inline-block;
              padding: 0.3rem 0;
              .main-genre {
                display: none !important;
              }
              .gridDescription {
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: normal;
                padding: 0 0.8rem;
              }
            }

          }
        }
      }
    }
  }  
}
</style>
